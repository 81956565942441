// @flow
import React from "react";
import { Link } from "gatsby";
import styles from "./TagsMenu.module.scss";
import kebabCase from "lodash/kebabCase";

type Props = {
  tags: {
    label: string,
    path: string,
  }[],
};

const TagsMenu = ({ tags }: Props) => (
  <nav className={styles["tags-menu"]}>
    <h4 className={styles["tags-menu__title"]}>Tags</h4>
    <p className={styles["tags-menu__list"]}>
      {tags.map((tag, i) => (
        <span
          className={styles["tags-menu__list-item"]}
          key={tag.path + "-" + i}
        >
          <Link
            to={`/tag/${kebabCase(tag.fieldValue)}/`}
            className={styles["tags-menu__list-item-link"]}
            activeClassName={styles["tags-menu__list-item-link--active"]}
          >
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </span>
      ))}
    </p>
  </nav>
);

export default TagsMenu;
