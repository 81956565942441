// @flow
import React from 'react';
import { withPrefix, Link } from 'gatsby';
import styles from './BlogInfo.module.scss';

type Props = {
  blogInfo: {
    name: string,
    bio: string,
    logo: string,
    logoDark: string
  },
  isIndex: Boolean,
  darkModeSite: Boolean
};

const BlogInfo = ({ blogInfo, isIndex, darkModeSite }: Props) => {

  return (
    <div className={styles['blog-info']}>
      <Link to="/">
        <img
          src={withPrefix(darkModeSite ? blogInfo.logoDark : blogInfo.logo)}
          className={styles['blog-info__logo']}
          height="80px"
          alt={blogInfo.name}
        />
      </Link>
      <p className={styles['blog-info__subtitle']}>{blogInfo.bio}</p>
    </div>
  );

}

export default BlogInfo;
