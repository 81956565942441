// @flow
import React from "react";
import { Link } from "gatsby";
import styles from "./CategoryMenu.module.scss";
import kebabCase from "lodash/kebabCase";

type Props = {
  categories: {
    label: string,
    path: string,
  }[],
};

const CategoryMenu = ({ categories, categoryMetas }: Props) => (
  <nav className={styles["category-menu"]}>
    <h4 className={styles["category-menu__title"]}>Categories</h4>
    <ul className={styles["category-menu__list"]}>
      {categories.map((category, i) => (
        <li
          className={styles["category-menu__list-item"]}
          key={category.path + "-" + i}
        >
          {/* <Link
            to={category.path}
            className={styles['category-menu__list-item-link']}
            activeClassName={styles['category-menu__list-item-link--active']}
          >
            {category.fieldValue} ({category.totalCount})
            {category.label}
          </Link> */}
          <Link to={`/category/${kebabCase(category.fieldValue)}/`}>
            <div
              className={styles["category-menu__list-item-link"]}
              activeClassName={styles["category-menu__list-item-link--active"]}
            >
              <img
                className={styles["category-menu__list-item-link-icon"]}
                src={categoryMetas[i].iconSrc}
              />
              {category.fieldValue} ({category.totalCount})
            </div>
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default CategoryMenu;
