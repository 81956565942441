// @flow
import React from "react";
import Author from "./Author";
import BlogInfo from "./BlogInfo";
import Contacts from "./Contacts";
import Copyright from "./Copyright";
import Menu from "./Menu";
import CategoryMenu from "./CategoryMenu";
import TagsMenu from "./TagsMenu";
import styles from "./Sidebar.module.scss";
import {
  useSiteMetadata,
  useCategoriesListAll,
  useTagsListAll,
} from "../../hooks";
import MailchimpForm from "../MailchimpForm/MailchimpForm";
import Divider from "./Divider";

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { copyright, menu, blogInfo, darkModeSite, categoryMetas } =
    useSiteMetadata();
  const categories = useCategoriesListAll();
  const tags = useTagsListAll();

  return (
    <div className={styles["sidebar"]}>
      <div className={styles["sidebar__inner"]}>
        <BlogInfo
          blogInfo={blogInfo}
          isIndex={isIndex}
          darkModeSite={darkModeSite}
        />
        <Divider />
        <Menu menu={menu} />
        <Divider />
        <Contacts contacts={blogInfo.contacts} />
        <Divider />
        {/* <MailchimpForm />
        <Divider /> */}
        <CategoryMenu categories={categories} categoryMetas={categoryMetas} />
        <Divider />
        <TagsMenu tags={tags} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
