// @flow
import React from "react";
import { Link } from "gatsby";
import styles from "./Menu.module.scss";

type Props = {
  menu: {
    label: string,
    path: string,
  }[],
};

const isExternal = function (path) {
  const isExtRgx = RegExp("(http|www)");
  return isExtRgx.test(path);
};

const Menu = ({ menu }: Props) => (
  <nav className={styles["menu"]}>
    <ul className={styles["menu__list"]}>
      {menu.map((item) => (
        <li className={styles["menu__list-item"]} key={item.path}>
          {isExternal(item.path) ? (
            <a
              href={item.path}
              className={styles["menu__list-item-link"]}
              activeClassName={styles["menu__list-item-link--active"]}
              target="_blank"
            >
              {item.label}
            </a>
          ) : (
            <Link to={item.path}>
              <div
                className={styles["menu__list-item-link"]}
                activeClassName={styles["menu__list-item-link--active"]}
              >
                <img
                  className={styles["menu__list-item-link-icon"]}
                  src={item.iconSrc}
                />
                {item.label}
              </div>
            </Link>
          )}
        </li>
      ))}
    </ul>
  </nav>
);

export default Menu;
